import React, { useContext, useState, useEffect } from "react";
import { Backdrop, Box, Button, CircularProgress, Container, Grid, Link, Paper, Skeleton, Slide, Typography } from "@mui/material";
import bkstApi from "../../common/api";
import ConfigContext from "../../common/context/ConfigContext";
import { Link as RouterLink } from "react-router-dom";
import Review from "../../common/component/Review";
import Masonry from "@mui/lab/Masonry";
import Flavors from "../../common/component/flavor/Flavors";
import { STORAGE_BUCKET } from "../../common/constants";
import { getRemoteConfigValue } from "../../common/firebase";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import Preview from "../../common/component/Preview";
import Stagger from "../../common/component/animate/Stagger";

export default function Home(props) {
  const siteConfig = useContext(ConfigContext);

  let location = useLocation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [section, setSection] = useState("");

  useEffect(() => {
    bkstApi(`/site/${siteConfig.id}/home`).then((res) => {
      setSection(res.data.section);
    });

    //getRemoteConfigValue("frenchycakedesigns").then((rslt) => { console.log(rslt) });
  }, []);

  useEffect(() => {
    const hash = location.hash.replace("#", "");
    const el = document.getElementById(hash);
    if (el) {
      el.scrollIntoView();
    }
  }, [section, location.hash]);

  let link = "/get-quote";
  //if (siteConfig.id === "uluI94ybtpj8z3r1gLQg") {
  //  link = "/rfq";
  //}

  if (section) {
    const img = (isMobile && section.hero.imgMobile) || section.hero.img;
    let hero = `url("${STORAGE_BUCKET}${img}")`;
    let heroOverlayColor = "#000";

    if (section.hero.overlay === "light") {
      // dark the background
      hero = `linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("${STORAGE_BUCKET}${img}")`;
      heroOverlayColor = "#FFF";
    }

    let isSingleCTA = false;
    if (siteConfig.header?.menu) {
      // custom menu, check if it has shop
      let shopMenu = siteConfig.header.menu.filter((o) => o.link === "/shop");
      if (shopMenu.length === 0) {
        isSingleCTA = true;
      }
    }

    let heroContainer = (
      <Container maxWidth="md" sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
        <Box sx={{ color: heroOverlayColor }}>
          <Typography style={{ color: heroOverlayColor, fontSize: "2.2rem", fontWeight: "700" }}>{siteConfig.biz}</Typography>
          <Typography style={{ color: heroOverlayColor, fontSize: "1.3rem" }}>{section.hero.subtitle}</Typography>
          <Box my={6} sx={{ display: "flex", gap: "20px", justifyContent: "space-evenly" }}>
            <Grid container spacing={2}>
              {isSingleCTA && <Grid item sm={3} xs={12}></Grid>}
              <Grid item sm={6} xs={12}>
                <Slide direction="right" in={true} mountOnEnter unmountOnExit timeout={1000}>
                  <Paper sx={{ height: "100%", opacity: ".9" }} elevation={16}>
                    <Box px={2} pt={3} pb={2} textAlign="center" sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                      <Box mb={2}>
                        <Typography variant="h4" gutterBottom>
                          {section.hero.primary?.title || "Custom Cakes & Treats"}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          {section.hero.primary?.subTitle || "Made to order custom cakes, cupcakes & more!"}
                        </Typography>
                      </Box>
                      <Button
                        component={RouterLink}
                        variant="contained"
                        color="primary"
                        size="large"
                        to={link}
                        fullWidth
                        style={{ fontSize: "20px" }}
                      >
                        Get Quote
                      </Button>
                    </Box>
                  </Paper>
                </Slide>
              </Grid>
              {!isSingleCTA && (
                <Grid item sm={6} xs={12}>
                  <Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={1000}>
                    <Paper sx={{ height: "100%", opacity: ".9" }} elevation={16}>
                      <Box px={2} pt={3} pb={2} textAlign="center" sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                        <Box mb={2} sx={{ flexGrow: "1" }}>
                          <Typography variant="h4" gutterBottom>
                            {section.hero.secondary?.title || "Everyday Cakes & Desserts"}
                          </Typography>
                          <Typography variant="body1">
                            {section.hero.secondary?.subtitle || "Our classic cakes & desserts available all day"}
                          </Typography>
                        </Box>
                        <Button
                          component={RouterLink}
                          variant="contained"
                          color="secondary"
                          size="large"
                          to={"/shop"}
                          fullWidth
                          style={{ fontSize: "20px" }}
                        >
                          Order Now
                        </Button>
                      </Box>
                    </Paper>
                  </Slide>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Container>
    );

    return (
      <Box>
        {section.banner && (
          <Box sx={{ backgroundColor: "#333", color: "#FFF", padding: "10px", textAlign: "center" }}>
            <Carousel
              autoPlay
              infiniteLoop
              interval={3000}
              transitionTime={1500}
              showArrows={false}
              showIndicators={false}
              showStatus={false}
              showThumbs={false}
            >
              {section.banner.map((o, idx) => {
                return (
                  <Link underline="none" component={RouterLink} to={o.link} sx={{ color: "inherit" }}>
                    <Box>
                      <Typography variant="body2">{o.text}</Typography>
                    </Box>
                  </Link>
                );
              })}
            </Carousel>
          </Box>
        )}
        <Box textAlign="center" sx={{ height: "90vh", backgroundImage: hero, backgroundSize: "cover" }}>
          {heroContainer}
        </Box>
        {siteConfig.id === "I2inIemAU72OXW5KsNQh" && (
          <Box p={2} sx={{ backgroundColor: "#FFF", display: "flex", justifyContent: "center" }}>
            <img src={`${STORAGE_BUCKET}/img/site/I2inIemAU72OXW5KsNQh/featured.jpg`} style={{ width: "100%", maxWidth: "750px" }} />
          </Box>
        )}
        {section.categories && (
          <Box py={15}>
            <Container maxWidth="lg">
              <Masonry columns={{ xs: 1, sm: 3 }} spacing={3}>
                {section.categories.list.map((o, idx) => {
                  return (
                    <Link key={idx} underline="none" component={RouterLink} to={o.link} sx={{ cursor: "pointer", color: "inherit" }}>
                      <Paper elevation={0}>
                        <Box>
                          <Box>
                            <Box>
                              <img style={{ width: "100%", height: "auto", aspectRatio: "1", objectFit: "cover" }} src={o.image} />
                            </Box>
                            <Box py={2} textAlign={"center"}>
                              <Typography variant="subtitle1">{o.title}</Typography>
                              {/* <Typography variant="body1" color="textSecondary">
                                {o.description}
                              </Typography> */}
                            </Box>
                          </Box>
                        </Box>
                      </Paper>
                    </Link>
                  );
                })}
              </Masonry>
            </Container>
          </Box>
        )}
        {section.photo && (
          <Box py={15}>
            <Box px={2} id="gallery">
              <Box textAlign="center" mb={8}>
                <Typography variant="h2" sx={{ fontSize: "2.75rem" }} gutterBottom>
                  Gallery
                </Typography>
                {/* <Typography variant="subtitle1" color="textSecondary">
                  {section.photo.subtitle}
                </Typography> */}
              </Box>
              <Box>
                {section.photo.data && (
                  <Masonry columns={{ xs: 2, sm: 3, md: 4 }} spacing={2}>
                    {section.photo.data.map((pic, idx) => {
                      return (
                        <Box key={idx}>
                          <Stagger idx={idx}>
                            <img style={{ width: "100%" }} src={pic} />
                          </Stagger>
                        </Box>
                      );
                    })}
                  </Masonry>
                )}
              </Box>
            </Box>
          </Box>
        )}
        {section.review && (
          <Box>
            <Box py={15} px={2} id="reviews" sx={{ backgroundColor: "#ECECEC" }}>
              <Box mb={8} textAlign="center">
                <Typography variant="h2" sx={{ fontSize: "2.75rem" }} gutterBottom>
                  {/* section.review.title */} Reviews
                </Typography>
              </Box>
              <Box>
                {section.review.data && (
                  <Masonry columns={{ xs: 1, sm: 2, md: 4 }} spacing={2}>
                    {section.review.data.map((el) => {
                      return (
                        <Box key={el.id}>
                          <Review data={el} baker={siteConfig.biz} />
                        </Box>
                      );
                    })}
                  </Masonry>
                )}
              </Box>
            </Box>
          </Box>
        )}
        <Box py={15}>
          <Container maxWidth="md">
            {/* <Box textAlign="center" id="flavors">
              <Typography variant="h2" gutterBottom>
                {section.flavor.title}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {section.flavor.subtitle}
              </Typography>
            </Box> */}
            <Box id="flavors">{section.flavor.data && <Flavors flavors={section.flavor.data.flavors} fillings={section.flavor.data.fillings} />}</Box>
          </Container>
        </Box>
        {section.about && (
          <Box py={15} sx={{ backgroundColor: "#ECECEC" }}>
            <Container maxWidth="lg">
              {section.about.list.map((o, idx) => {
                return (
                  <Box key={idx} my={6}>
                    {o.mediaUrl.startsWith("<iframe") ? (
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: o.mediaUrl
                        }}
                      ></Box>
                    ) : (
                      <Masonry columns={{ xs: 1, sm: 2 }} spacing={4}>
                        <Box>
                          {o.mediaUrl.startsWith("<iframe") ? (
                            <Box
                              dangerouslySetInnerHTML={{
                                __html: o.mediaUrl
                              }}
                            ></Box>
                          ) : (
                            <img style={{ width: "100%", borderRadius: "4px" }} src={o.mediaUrl} />
                          )}
                        </Box>
                        <Box>
                          <Preview lines="12">
                            <Typography
                              sx={{ textAlign: "justify" }}
                              dangerouslySetInnerHTML={{
                                __html: o.content
                              }}
                            />
                          </Preview>
                        </Box>
                      </Masonry>
                    )}
                  </Box>
                );
              })}
            </Container>
          </Box>
        )}
      </Box>
    );
  } else {
    return (
      <Backdrop open={true} style={{ zIndex: "1000", color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
}
