import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  InputAdornment,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  IconButton
} from "@mui/material";
import bkstApi from "../../common/api";
import currency from "currency.js";
import ConfigContext from "../../common/context/ConfigContext";
import { getBookingMetadata, getItemBasePrice, getVariantTotal } from "../../common/util";
import ImageCarousel from "../../common/component/image/ImageCarousel";
import BDatePicker from "../../common/component/BDatePicker";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import RequestToBook from "../../common/component/RequestToBook";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useNavigate } from "react-router";
import ProductDescription from "../../product/component/ProductDescription";

export default function ClassPage() {
  const siteConfig = useContext(ConfigContext);
  const navigate = useNavigate();

  const [product, setProduct] = useState("");
  const { productId } = useParams();

  const [variant, setVariant] = useState("");
  const [quantity, setQuantity] = useState("1");
  const [variantPrice, setVariantPrice] = useState("");

  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");

  const [stage, setStage] = useState("loading");
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setStage("loading");

    bkstApi(`/site/${siteConfig.id}/class/${productId}`).then((res) => {
      setProduct(res.data.product);

      window.scrollTo(0, 100);
      setStage("loaded");
    });
  }, [productId]);

  useEffect(() => {
    if (product) {
      setVariantPrice(getVariantTotal(variant, getItemBasePrice(variant)));
    }
  }, [variant, quantity]);

  const validateQuantity = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  const handleQuantityChange = (value) => {
    const newValue = +quantity + +value;
    if (newValue > 0) {
      setQuantity(newValue);
    }
  };

  const validate = () => {
    let errors = [];
    if (!date) {
      errors.push("Date is missing");
    }
    if (!startTime) {
      errors.push("Start time is missing");
    }
    /*if(!attendees){
      errors.push("Attendee count is required");
    }*/

    setErrors(errors);
    return errors.length === 0;
  };

  const submitInquiry = (contactInfo) => {
    bkstApi
      .post(`/site/${siteConfig.id}/product/${productId}/inquiry`, { variant: { date, startTime }, customer: contactInfo, title: product.title })
      .then((res) => {
        setDate("");
        setStartTime("");

        setStage("done");
      });
  };

  const proceedToBooking = (e) => {
    e.preventDefault();

    let utm = "";
    const utmParams = sessionStorage.getItem("utm");
    if (utmParams) {
      utm = JSON.parse(utmParams);
    }

    const item = {
      type: "class",
      refId: product.id,
      type: product.type,
      images: [product.images[0]],
      title: product.title,
      price: variantPrice,
      quantity,
      variant
    };

    bkstApi.post(`/site/${siteConfig.id}/booking`, { item, utm }).then((res) => {
      navigate(`/booking/${res.data.cartId}`);
    });
  };

  if (stage === "loading") {
    return <LinearProgress />;
  } else {
    const metadata = getBookingMetadata(product);

    const hasDateOptions = Boolean(product.options?.session?.options);

    return (
      <Box>
        <Container maxWidth="lg">
          <Box pt={10} pb={20}>
            <Grid container spacing={6}>
              <Grid item sm={7} xs={12}>
                <ImageCarousel images={product.images} style={{ width: "100%", borderRadius: "4px" }} />
              </Grid>
              <Grid item sm={5} xs={12}>
                <Paper elevation={0}>
                  <Box px={3} py={3}>
                    <Box>
                      <Typography variant="h3" gutterBottom>
                        {product.title}
                      </Typography>
                      <Typography variant="body2">{product.subtitle}</Typography>
                    </Box>
                    {!hasDateOptions && (
                      <Box my={2} py={2} sx={{ borderTop: "3px solid #DEDEDE", borderBottom: "3px solid #DEDEDE" }}>
                        {product.variant?.duration && (
                          <Box my={0.75}>
                            <Typography style={{ display: "flex", alignItems: "center", fontSize: "1em" }}>
                              <AccessTimeIcon style={{ fontSize: "1.2em", marginRight: "5px" }} />
                              {product.variant.duration} hours
                            </Typography>
                          </Box>
                        )}
                        {product.rules?.capacity && (
                          <Box my={0.75}>
                            <Typography style={{ display: "flex", alignItems: "center", fontSize: "1em" }}>
                              <FaceOutlinedIcon style={{ fontSize: "1.2em", marginRight: "5px" }} />
                              {product.rules?.capacity.min} - {product.rules?.capacity.max} attendees
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    )}
                    <ProductDescription description={product.description} />

                    {product.options.session && (
                      <Box>
                        <form onSubmit={proceedToBooking}>
                          <Box my={4}>
                            <FormControl variant="outlined" fullWidth required>
                              <InputLabel id="session-label">Date & Time</InputLabel>
                              <Select
                                value={variant?.session?.date}
                                labelId="session-label"
                                label={`Date & Time`}
                                id="session"
                                name="session"
                                renderValue={(option) => {
                                  return `${moment(option.date, "YYYY-MM-DD").format("ddd, MMM D, YYYY")} ● ${option.time}`;
                                }}
                                onChange={(e) => {
                                  setVariant({ ...variant, session: e.target.value });
                                }}
                              >
                                {product.options.session.options.map((option, idx) => {
                                  return (
                                    <MenuItem key={idx} value={option} style={{ whiteSpace: "normal" }}>
                                      <Box>
                                        <Typography variant="body1" gutterBottom>
                                          {moment(option.date, "YYYY-MM-DD").format("ddd, MMM D, YYYY")}{" "}
                                          <span style={{ color: "#999" }}>{` ● `}</span>
                                          {option.time}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                          {currency(option.pricing.amount).format()}
                                        </Typography>
                                      </Box>
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Box>
                          <Box my={4}>
                            <Box sx={{ display: "flex", gap: "30px", alignItems: "center" }}>
                              <TextField
                                type="number"
                                name="quantity"
                                label="Quantity"
                                value={quantity}
                                variant="outlined"
                                id="quantity"
                                onChange={(e) => setQuantity(e.target.value)}
                                inputProps={{
                                  sx: { textAlign: "center" }
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <IconButton onClick={() => handleQuantityChange(-1)} disabled={quantity === 1}>
                                        <RemoveIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton onClick={() => handleQuantityChange(1)}>
                                        <AddIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                                sx={{
                                  width: "125px",
                                  "& .MuiOutlinedInput-root": {
                                    paddingRight: 0,
                                    paddingLeft: 0
                                  },
                                  backgroundColor: "#FFFFFF"
                                }}
                                onKeyPress={validateQuantity}
                              />
                              <Typography variant="h5">{+variantPrice > 0 && currency(variantPrice * quantity).format()}</Typography>
                            </Box>
                          </Box>
                          {product.status === "DRAFT" && (
                            <Box my={2}>
                              <Alert severity="error">Sorry, this item is no longer available!</Alert>
                            </Box>
                          )}
                          <Box my={4}>
                            <Button fullWidth size="large" variant="contained" color="primary" type="submit" disabled={product.status === "DRAFT"}>
                              Procced to Checkout
                            </Button>
                          </Box>
                        </form>
                      </Box>
                    )}
                    {!hasDateOptions && (
                      <Box my={4} px={2} py={4} sx={{ borderTop: "3px solid #DEDEDE" }}>
                        <Box>
                          <FormControl variant="outlined" fullWidth>
                            <BDatePicker
                              value={date || null}
                              label={`Date`}
                              onChange={(d) => setDate(d)}
                              disablePast
                              required
                              //shouldDisableDate={(d) => shouldDisableDate(d, dateRules, location)}
                            />
                          </FormControl>
                        </Box>
                        <Box my={4}>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="startTime-label">{`Time`}</InputLabel>
                            <Select
                              variant="outlined"
                              value={startTime}
                              labelId="startTime-label"
                              label="Time"
                              id="startTime"
                              name="startTime"
                              onChange={(e) => setStartTime(e.target.value)}
                            >
                              {["10am", "11am", "12pm", "1pm", "2pm", "3pm", "4pm", "5pm"].map((i) => (
                                <MenuItem key={i} value={i}>
                                  {i}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>

                        {errors?.length > 0 && (
                          <Box mb={2}>
                            <Alert severity="error">{errors.join(". ")}</Alert>
                          </Box>
                        )}
                        {stage === "done" && <Alert severity="success">We've received your inquiry and we'll be in touch shortly.</Alert>}
                        <Box my={2}>
                          <RequestToBook validate={validate} onSubmit={(payload) => submitInquiry(payload)} />
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    );
  }
}
