import moment from "moment";
import currency from "currency.js";

export function getQueryParam(queryStr, p) {
  const arr = queryStr.replace("?", "").split("&");
  for (const a of arr) {
    if (a.startsWith(`${p}=`)) {
      return decodeURIComponent(a.substring(p.length + 1));
    }
  }
  return undefined;
}

export function capitalize(s) {
  const words = s.split(" ");
  for (let i = 0; i < words.length; i++) {
    if (words[i]) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
  }
  return words.join(" ");
}

export function createSnippets(productType, shape, size, servings, eventDate) {
  const data = [];
  if (productType === "Cake") {
    if (shape && size) {
      data.push(`${shape}, ${size} cake`);
    }
    if (servings) {
      data.push(`${servings} servings`);
    }
    data.push(`Choice of flavor`);
    data.push(`Custom inscription`);
  } else {
    if (productType && servings) {
      data.push(`${servings} ${productType}`);
    }
    if (eventDate) {
      data.push(`For ${moment(eventDate).format("ddd, MMM DD, YYYY")}`);
    }
  }

  return data;
}

export function generateServingsSnippet(servings) {
  if (servings.length < 7) {
    return servings + " servings";
  } else {
    return servings;
  }
}

export function generateTitle(items, keyword) {
  let title = "";

  if (Object.keys(items).length === 1) {
    // single item
    title = `${keyword || "Custom"} ${Object.keys(items)[0]}`;
  } else {
    // multiple items
    if (items["Cake"]) {
      title += `${keyword || "Custom"} Cake & Treats`;
    } else {
      title += `Custom Treats`;
    }
  }

  return capitalize(title);
}

export function generateProductSnippets(productType, shape, size, servings) {
  const data = [];
  if (productType === "Cake") {
    if (shape && size) {
      data.push(`${shape}, ${size} cake`);
    }
    if (servings) {
      data.push(`${servings} servings`);
    }
  } else {
    if (productType && servings) {
      data.push(`${servings} ${productType}`);
    }
  }

  return data;
}

export function generateSizeSummary(variant) {
  let summary = "";

  if (variant) {
    if (variant.shape) {
      summary += `${variant.shape} • `;
    }
    if (variant.size) {
      summary += `${variant.size} • `;
    }
    if (variant.servings) {
      summary += `${variant.servings} servings`;
    }
  }

  return summary;
}

export function generateFlavorSummary(arr) {
  let summary = "";
  if (Array.isArray(arr)) {
    for (const o of arr) {
      if (o.name) {
        summary = `Flavor: ${o.name}`;
      }

      if (o.filling) {
        summary += ` • Filling: ${o.filling}`;
      }
    }
  } else if (typeof arr === "object") {
    if (arr.base) {
      summary = `Flavor: ${arr.base.name}`;
    }
    if (arr.filling) {
      summary += ` • Filling: ${arr.filling.name}`;
    }
  }

  return summary;
}

export function getCustomerName(customer) {
  if (customer.firstName && customer.lastName) {
    return `${customer.firstName} ${customer.lastName}`;
  } else if (customer.name && customer.lastName) {
    return `${customer.name} ${customer.lastName}`;
  } else {
    return customer.name || "";
  }
}

export function getAddress(location) {
  let address = "";
  if (location.street) {
    address = `${location.street}, `;
  }

  address += `${location.city}, ${location.state} ${location.zip}`;
  return address;
}

export function getDaysWithNoTimeslots(timeslots) {
  let days = [];
  for (const [key, item] of Object.entries(timeslots)) {
    if (!item || item.length === 0) {
      days.push(key.toLowerCase());
    }
  }
  return days;
}

export function getDateRules(items, timeslots) {
  let rules = { daysToExclude: getDaysWithNoTimeslots(timeslots) };

  for (const item of items) {
    if (item.rules && item.rules.date) {
      rules = { ...rules, ...item.rules.date };
    }
  }
  return rules;
}

export function isStoreClosed(siteId, date) {
  const dt = moment(date).format("YYYY-MM-DD");

  if (siteId === "6VRiUORmuZ7IORV5CONz") {
    if (
      dt === "2024-07-15" ||
      dt === "2024-07-16" ||
      dt === "2024-07-17" ||
      dt === "2024-07-18" ||
      dt === "2024-07-19" ||
      dt === "2024-07-20" ||
      dt === "2024-07-21" ||
      dt === "2024-07-22"
    ) {
      return true;
    }
  } else if (siteId === "-MROBNjX5PgS42T785uI") {
    if (dt === "2024-08-11" || dt === "2024-08-12" || dt === "2024-08-13" || dt === "2024-08-14" || dt === "2024-08-15") {
      return true;
    }
  } else if (siteId === "ZJg1JT1mPlCPHop4Gfut") {
    if (dt === "2024-08-04" || dt === "2024-08-11" || dt === "2024-08-18" || dt === "2024-08-25") {
      return true;
    }
  } else if (siteId === "aqabe9A2spBl5fiN2iYb") {
    //Cl
    if (
      dt === "2024-09-07" ||
      dt === "2024-09-08" ||
      dt === "2024-09-11" ||
      dt === "2024-09-12" ||
      dt === "2024-09-13" ||
      dt === "2024-09-14" ||
      dt === "2024-09-15" ||
      dt === "2024-09-22"
    ) {
      return true;
    }
  } else if (siteId === "OO1GYzmPM4HEUwFvOD5K") {
    // Kris
    if (dt === "2024-08-25" || dt === "2024-08-26" || dt === "2024-08-27" || dt === "2024-08-28" || dt === "2024-08-29" || dt === "2024-08-30") {
      return true;
    }
  } else if (siteId === "-MVIf1diaYOCm5KynVDF" || siteId === "-MX2oaNrh4TcKVvnhcT9") {
    if (dt === "2024-09-18") {
      return true;
    }
  }

  return false;
}

export function shouldDisableDateV3(siteId, date, dateRules, fulfillment, locationRules) {
  if (dateRules) {
    if (dateRules.min) {
      if (moment(date).isBefore(moment(dateRules.min))) {
        return true;
      }
    }

    if (dateRules.max) {
      if (moment(date).isAfter(moment(dateRules.max))) {
        return true;
      }
    }

    if (dateRules.daysToExclude) {
      const day = moment(date).format("ddd").toLowerCase();
      if (dateRules.daysToExclude.includes(day)) {
        return true;
      }
    }

    if (dateRules.leadTime) {
      // if leadTime extends through end of day, then disable date
      const hoursToEndOfDay = moment(date).endOf("day").diff(moment(), "hours") + getTimezoneOffset();
      if (+hoursToEndOfDay < +dateRules.leadTime) {
        return true;
      }
    }
  }

  // Metrobi exclusion
  if (fulfillment && fulfillment.shipping === "delivery" && fulfillment.provider.name === "metrobi") {
    // 24-hour lead time
    const hoursToStartOfDay = moment(date).startOf("day").diff(moment(), "hours") + getTimezoneOffset();
    if (+hoursToStartOfDay < 10) {
      return true;
    }
  }

  // date exclusion
  if (locationRules) {
    if (locationRules?.date?.block.includes(moment(date).format("YYYY-MM-DD"))) {
      return true;
    }
  } else if (isStoreClosed(siteId, date)) {
    return true;
  }

  return false;
}

export function isStoreClosedonDate(d, storeLocation) {
  const date = moment(d);

  let timeslotsByDayOfWeek = storeLocation.fulfillment?.pickup?.timeslots?.[date.format("ddd").toLowerCase()] || [];

  if (timeslotsByDayOfWeek.length === 0) {
    return true;
  } else if (storeLocation.rules?.date?.block.includes(date.format("YYYY-MM-DD"))) {
    return true;
  }

  return false;
}

export function getStoreClosedDaysOfWeek(storeLocation) {
  const DAY_MAP = {
    sun: 1,
    mon: 2,
    tue: 3,
    wed: 4,
    thu: 5,
    fri: 6,
    sat: 7
  };

  let arr = [];
  for (const [key, value] of Object.entries(storeLocation.fulfillment?.pickup?.timeslots)) {
    if (value?.length === 0) {
      arr.push(DAY_MAP[key]);
    }
  }

  return arr.sort();
}

export function getItemBasePrice(variant) {
  for (const [key, value] of Object.entries(variant)) {
    if (key === "size") {
      return +value.pricing.amount;
    }
  }

  return 0;
}

export function getVariantTotal(variant, basePrice) {
  let total = 0;
  for (const [key, value] of Object.entries(variant)) {
    if (value?.pricing) {
      if (value.pricing.amount) {
        total += +value.pricing.amount;
      } else if (value.pricing.factor) {
        total += currency(basePrice).multiply(value.pricing.factor).value;
      }
    } else if (typeof value === "object") {
      total += getVariantTotal(value, basePrice);
    }
  }
  return +total;
}

export function getAvailableTimeslotsbyDate(location, date, dateRules) {
  let slotsbyDate = location?.timeslots[moment(date).format("ddd").toLowerCase()];
  let today = moment();

  // check today's timeslots
  if (moment(date).format("YYYY-MM-DD") === today.format("YYYY-MM-DD")) {
    console.log("TODAY");

    const currentHour = today.hour() + getTimezoneOffset();
    console.log("currentHour", currentHour, today.hour());

    let arr = [];
    for (const o of slotsbyDate) {
      const hour = getStartTime(o);
      if (+hour > currentHour) {
        arr.push(o);
      }
    }
    slotsbyDate = arr;
  }

  // check leadTime
  if (slotsbyDate && dateRules.leadTime) {
    // do we even need to check time?
    const hoursToStartOfDay = Math.ceil(moment(date).startOf("day").diff(moment(), "hours", true)) + getTimezoneOffset();
    if (+dateRules.leadTime > hoursToStartOfDay) {
      let arr = [];
      for (const o of slotsbyDate) {
        const hourOfDay = getStartTime(o);
        const durationToTimeslot = hoursToStartOfDay + hourOfDay;
        if (durationToTimeslot >= +dateRules.leadTime) {
          arr.push(o);
        }
      }
      slotsbyDate = arr;
    }
  }

  return slotsbyDate;
}

export function getStartTime(str) {
  let hour = 0;
  const time = (str?.trim() || "").toLowerCase();

  const amIdx = time.indexOf("am");
  const noonIdx = time.indexOf("noon");
  const pmIdx = time.indexOf("pm");

  // find first occurence
  const arr = [amIdx, noonIdx, pmIdx].filter((idx) => idx >= 0);
  if (arr.length > 0) {
    // split time string at the first match
    const splitIdx = Math.min(...arr);
    if (noonIdx === splitIdx) {
      hour = 12;
    } else {
      // split string and extract hour
      hour = parseInt(time.substring(0, splitIdx));
      if (pmIdx === splitIdx) {
        if (hour !== 12) {
          hour = hour + 12;
        }
      }
    }
  }

  return hour;
}

export function getTimezoneOffset() {
  const storeLocationTimezoneOffset = 4;
  const clientTimezoneOffset = Math.floor(new Date().getTimezoneOffset() / 60);

  const offset = clientTimezoneOffset - storeLocationTimezoneOffset;

  return offset;
}

export const formatPhoneNumber = (value) => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  var match = phoneNumber.match(/^(\d{1,3}|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? `+${match[1]} ` : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  } else {
    return value;
  }
};

export function getCustomerFirstNameLastInitial(name) {
  // Create an array containing each of the words in the name
  var names = name.split(/\s+/);

  if (names.length > 1) {
    // Replaces the first name with an initial, followed by a period.
    names[1] = names[1].substr(0, 1) + ".";
  }

  // Glue the pieces back together.
  return names.join(" ");
}

export function isAssistedCheckout() {
  const isPageInIFrame = window.location !== window.parent.location;
  const isAssistedCheckout = sessionStorage.getItem("su");
  return isPageInIFrame && isAssistedCheckout;
}

export function getItemMetadata(product) {
  let [minPrice, maxPrice, leadTime] = [0, 0, 0];
  let dietary = new Set();
  if (product.options.size) {
    for (const variant of product.options?.size) {
      const price = +variant.pricing.amount;
      if (minPrice === 0) {
        minPrice = price;
      } else if (price <= minPrice) {
        minPrice = price;
      } else if (price >= maxPrice) {
        maxPrice = price;
      }

      const title = variant.size.toLowerCase();
      if (title.includes("vegan")) {
        dietary.add("VG");
      }

      if (title.includes("gluten") && title.includes("free")) {
        dietary.add("GF");
      }

      leadTime = product.rules?.date?.leadTime || 0;
    }
  }

  return { price: { min: minPrice, max: maxPrice }, dietary: Array.from(dietary), leadTime };
}

export function getBookingMetadata(product) {
  // inspect rules
  let leadTime = product.rules?.date?.leadTime || 0;
  let capacity = product.rules?.space?.capacity || 0;
  let duration = product.rules?.duration?.min || 0;
  let area = product.rules?.space?.area || 0;

  return { leadTime, capacity, area, duration };
}

export function getStoreAddress(store) {
  return `${store.address.street}, ${store.address.city}, ${store.address.state} ${store.address.zip}`;
}

export function getStaticMapUrl(stores) {
  const mainLocation = stores[0];
  let url = `https://maps.googleapis.com/maps/api/staticmap?scale=2`;

  // now add markers
  stores.forEach((store, index) => {
    let markerConfig = "";
    if (stores.length > 1) {
      markerConfig = `label:${index + 1}|`;
    }
    markerConfig += getStoreAddress(store);

    url += `&markers=${encodeURIComponent(markerConfig)}`;
  });

  url += `&maptype=roadmap&size=640x320&key=AIzaSyALpb-XVmhGjST5IRtRW-yGcbsIqLc7gJs`;

  console.log("return", url);
  return url;
}

export function isTruncated(element) {
  if (element && (element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth)) {
    return true;
  } else {
    return false;
  }
}

export function normalizeFlavor(value) {
  if (Array.isArray(value)) {
    return value[0];
  } else if (typeof value === "object") {
    return { name: value?.base?.name, filling: value?.filling?.name };
  } else {
    return "";
  }
}

export function generateItemSnippets(item) {
  const data = [];

  if (item.variant) {
    if (item.variant.size) {
      let arr = [];
      if (item.variant.size.shape) {
        arr.push(item.variant.size.shape);
      }
      if (item.variant.size.size) {
        arr.push(item.variant.size.size);
      }
      if (item.variant.size.servings) {
        arr.push(`${item.variant.size.servings} servings`);
      }
      if (arr.length > 0) {
        data.push(`Size • ${arr.join(", ")}`);
      }
    }

    if (item.variant.frosting) {
      data.push(`Icing • ${item.variant.frosting.name}`);
    }
    if (item.variant.flavor) {
      const flavor = normalizeFlavor(item.variant.flavor);
      if (flavor.name) {
        data.push(`Flavor • ${flavor.name}`);
      }

      if (flavor.filling) {
        data.push(`Filling • ${flavor.filling}`);
      }
    }
    for (const [key, value] of Object.entries(item.variant)) {
      if (["size", "flavor", "frosting", "add-on", "session"].includes(key)) {
        continue;
      } else {
        data.push(`${capitalize(key)} • ${value?.name}`);
      }
    }
    if (item.variant?.[`add-on`]) {
      const addOnValues = item.variant?.[`add-on`].map((o) => o.name).join(", ");
      data.push(`Add-Ons • ${addOnValues}`);
    }
    if (item.variant.session) {
      data.push(`Session • ${moment(item.variant.session.date, "YYYY-MM-DD").format("MMM D, YYYY")} ${item.variant.session.time}`);
    }
  }

  if (item.customization) {
    if (item.customization.inscription && item.customization.inscription.text) {
      data.push(`Inscription: ${item.customization.inscription.text}`);
    }
  }

  if (item.tags) {
    data.push(...item.tags);
  }

  return data;
}

export function getFormattedAddressAsArray(location, includeUnit) {
  let arr = [];

  if (location) {
    let line1 = location.street;
    if (includeUnit && location.unit) {
      line1 += `, ${location.unit}`;
    }
    arr.push(line1);
    arr.push(`${location.city}, ${location.state} ${location.zip}`);
  }
  return arr;
}
